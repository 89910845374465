import React from 'react'
import Img from "gatsby-image";
import { graphql } from 'gatsby'
import splash from './splash.module.css';

import Layout from '../components/layout'

const styles = {
  centered: {
    textAlign: 'center',
  },
  bodywrap: {
    marginTop: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius: 10,
    boxShadow: `0px 0px 33px -4px rgba(0,0,0,0.49)`,
    padding: '2rem',
  },
  headerImage: {
    // width: '100%',
    display: 'flex',
    flex: 1,
    maxWidth: 300,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    // display: 'flex',
    // alignSelf: 'stretch',
    // alignItems: 'center',
  },
  videoContainer: {
    flex: 1, 
    display: 'flex',
    minHeight: 350,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoTitle: {
    textAlign: 'center',
  },
};

const IndexPage = ({ data }) => {
  const v = data.site.siteMetadata.videos[1];
  return (
    <Layout>
      <section style={styles.bodywrap}>
        {
          <div>
            <a className={splash.url} href={ v.url } target="_blank">
              <h2 style={styles.videoTitle}>{ v.title }</h2>
            </a>
            <div
              style={styles.videoContainer}
              dangerouslySetInnerHTML={{ __html: v.embed }}
            />
          </div>
        }
        <Img
          outerWrapperClassName={splash.imageWrapper}
          style={styles.headerImage}
          sizes={data.logo.childImageSharp.sizes}
        />
        {
          <div>
            <p
              style={styles.description}
              dangerouslySetInnerHTML={{ __html: v.description }}
            />
          </div>
        }
      </section>
    </Layout>
  );
}

export const query = graphql`
    query homeQuery {
      site {
        siteMetadata {
          videos {
            title
            url
            embed
            description
          }
        }
      }

      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          resolutions(width: 200, height: 125) {
            ...GatsbyImageSharpResolutions
          }
          sizes {
            ...GatsbyImageSharpSizes_noBase64
          }
        }
      }
    }
    `;

export default IndexPage
